import { RotateLeftOutlined, UndoOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row, Space } from 'antd'
import { ImageDrawer, ImageDrawerAPI } from 'components/ImageDrawer/ImageDrawer'
import { RichText } from 'components/Preview/RichText'
import { CurrentQuestionContext } from 'hooks/CurrentQuestionContext'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import _ from 'lodash'
import { useMarkingForm } from 'pages/marking-form/useMarkingForm'
import React, { FC, useContext, useMemo, useRef, useState } from 'react'
import { QuestionIdentity } from 'typing.paper'
import { AnswerImage, pickImageFromAnswer } from 'utils'
import { isAnswerNesting, isAnswerNormals } from 'utils/answer'
import { Marking } from '../../typing'
import { CommentDialog } from './modals/CommentDialog'

const log = require('debug')('StudentAnswerDrawerRender')

type StudentAnswerDrawerRenderProps = {
  answerData:
    | string
    | Marking.AnswerNormal[]
    | Marking.AnswerNesting
    | undefined
  readonly?: boolean
}
export const StudentAnswerDrawerRender: FC<StudentAnswerDrawerRenderProps> = props => {
  const { answerData, readonly } = props

  const { question, parentQuestion } = useContext(CurrentQuestionContext)
  if (!question) {
    throw new Error('question is required')
  }

  const {
    commentForm,
    getCommentImage,
    removeCommentImage
  } = useQuestionAnswerDataContent()
  const {
    setQuestionScore,
    setNestingQuestionScore,
    registerDrawerContext
  } = useMarkingForm()

  if (!answerData) {
    return null
  }

  // Pick image from answer
  // Render image in drawer
  // Render Button to open modal that show original answer
  const images = useMemo(() => {
    let images: AnswerImage[] = []

    if (typeof answerData === 'string') {
      images = pickImageFromAnswer(answerData)
    } else if (isAnswerNormals(answerData)) {
      images = _.map(answerData, (a, namedQuestionIndex) => {
        if (a.name) {
          return pickImageFromAnswer(a.content, namedQuestionIndex)
        } else {
          return pickImageFromAnswer(a.content || a.answer)
        }
      }).flat()
    } else if (isAnswerNesting(answerData)) {
      images = pickImageFromAnswer(answerData.answer)
    }

    // Find comment image
    images.forEach(img => {
      const commentImage = getCommentImage(question.id, img)
      if (commentImage) img.commentImageUrl = commentImage
    })

    log('images', images)

    return images
  }, [answerData, commentForm, parentQuestion, question])

  function getImageProxyUrl(url: string) {
    return `/image_proxy?url=${url.replace('http://', 'https://')}`
  }

  function onScoreChange(score: number | null, question?: QuestionIdentity) {
    if (!question) {
      throw new Error('question is required')
    }
    setQuestionScore(question, score)
  }

  // CommentDialog
  const [commentDialogVisible, setCommentDialogVisible] = useState(false)

  // AnswerViewer
  const [answerViewerVisible, setAnswerViewerVisible] = useState(false)

  // Drawer
  const drawerAPIs = useRef<ImageDrawerAPI[]>([])

  function onRotate(i: number) {
    drawerAPIs.current[i]?.rotate('l')
  }

  function onReset(i: number) {
    // update images at i

    // update comment
    removeCommentImage({
      questionId: question!.id,
      image: images[i]
    })

    // reset drawer
    // In case drawer is not re-rendered by url
    drawerAPIs.current[i]?.clean()
  }

  function onUndo(i: number) {
    drawerAPIs.current[i]?.undo()
  }

  function onRedo(i: number) {
    drawerAPIs.current[i]?.redo()
  }

  return (
    <>
      {!images.length && <AnswerRender data={answerData}></AnswerRender>}
      {images.map((img, i) => (
        <div key={i} className='answer-inline-drawer-container'>
          <ImageDrawer
            ref={r => {
              if (r) {
                drawerAPIs.current[i] = r
                registerDrawerContext({
                  questionIdentity: question.id,
                  image: img,
                  getImage: r.getImageData
                })
              }
            }}
            key={i}
            url={getImageProxyUrl(img.commentImageUrl ?? img.url)}
            readonly={readonly}
            inline={true}
            onCurrentScoreChange={onScoreChange}
            onConfirm={() => {}}
            onReset={() => onReset(i)}
          />

          <div className='toolbar'>
            <Space>
              <Button
                size='small'
                icon={<RotateLeftOutlined />}
                onClick={() => onRotate(i)}
              >
                旋转
              </Button>
              <Button
                size='small'
                icon={<UndoOutlined />}
                onClick={() => onUndo(i)}
              >
                撤销
              </Button>
              {/* <Button icon={<RedoOutlined />} onClick={() => onRedo(i)}>
                重做
              </Button> */}
            </Space>
          </div>
        </div>
      ))}

      <div className='answer-inline-drawer-extra-toolbar'>
        <Space>
          {readonly ? (
            <Button
              type='primary'
              onClick={() => setCommentDialogVisible(true)}
            >
              查看批注
            </Button>
          ) : (
            <Button
              type='primary'
              onClick={() => setCommentDialogVisible(true)}
            >
              写批注
            </Button>
          )}
          {!!images.length && (
            <Button onClick={() => setAnswerViewerVisible(true)}>
              查看原始作答
            </Button>
          )}
        </Space>
      </div>

      {commentDialogVisible && (
        <CommentDialog
          questionId={question.id}
          allowEdit={!readonly}
          onClose={() => setCommentDialogVisible(false)}
        />
      )}

      {answerViewerVisible && (
        <Modal
          title='查看原始作答'
          visible={true}
          onCancel={() => setAnswerViewerVisible(false)}
          footer={null}
          width={600}
        >
          <AnswerRender data={answerData} />
        </Modal>
      )}
    </>
  )
}

const AnswerRender: FC<{
  data: StudentAnswerDrawerRenderProps['answerData']
}> = ({ data: answerData }) => {
  if (!answerData) return <RichText />

  if (typeof answerData === 'string') {
    return <RichText html={answerData} />
  }

  if (isAnswerNormals(answerData)) {
    const showIndex = answerData.length > 1
    return (
      <>
        {_.map(answerData, (a, i) => {
          if (a.name) {
            return (
              <Row key={i}>
                {showIndex && <Col style={{ marginRight: 8 }}>{a.name}.</Col>}
                <Col flex={1}>
                  <RichText html={a.content} />
                </Col>
              </Row>
            )
          }
          return <RichText key={i} html={a.content || a.answer} />
        })}
      </>
    )
  }

  if (isAnswerNesting(answerData)) {
    return <RichText html={answerData.answer} />
  }

  return <RichText />
}
