import { useLocalStorageState } from '@umijs/hooks'
import constate from 'constate'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Paper } from 'typing'
import {
  DrawerScoreTextAutoComputeByMarking,
  DrawerScoreTextSignMode
} from 'typing.marking'
import {
  applyScoreText,
  getScoreTextObjects,
  parseScoreTextObjects
} from './utils'

export type ToolMode =
  | 'default'
  | 'pan'
  | 'line'
  | 'text'
  | 'crop'
  | 'score'
  | 'marker-right'
  | 'marker-wrong'
  | 'marker-half-right'

export type ScoreSign = 'plus' | 'minus'

export const ScoreTextPrefix = '______ '

const [DrawerProvider, useDrawerContent] = constate(() => {
  const { markingPaper } = useQuestionAnswerDataContent()

  const settingScoreTextSignMode = useMemo(
    () => markingPaper?.score_plus_mode ?? DrawerScoreTextSignMode.ALL,
    [markingPaper]
  )

  const settingScoreAutoComputeByScoreText = useMemo(
    () =>
      markingPaper?.score_auto_count ?? DrawerScoreTextAutoComputeByMarking.NO,
    [markingPaper]
  )

  // Score marker settings
  const [scoreMarkerPrefix, setScoreMarkerPrefix] = useLocalStorageState(
    'marking-score-marker-prefix',
    ''
  )

  const [
    isScoreMarkerBracketWrapped,
    toggleScoreMarkerBracketWrapped
  ] = useLocalStorageState('marking-score-marker-bracket-wrapped', false)

  const [toolMode, _setToolMode] = useState<ToolMode>('default')

  const setToolMode = useCallback(
    (mode: ToolMode, force = false) => {
      if (force) {
        _setToolMode(mode)
        return
      }

      if (mode === toolMode) {
        _setToolMode('default')
      } else {
        _setToolMode(mode)
      }
    },
    [toolMode]
  )

  const [color, setColor] = useLocalStorageState(
    'marking-drawer-color',
    '#ff0000'
  )

  const [fontSize, setFontSize] = useLocalStorageState(
    'marking-drawer-fontSize',
    24
  )

  const [panSize, setPanSize] = useLocalStorageState(
    'marking-drawer-panSize',
    2
  )

  const [score, setScore] = useState<number>(1)
  const [scoreSignMode, setScoreSignMode] = useState<ScoreSign>('minus')
  const [scoreSignReadonly, setScoreSignReadonly] = useState(false)

  // Initial sign mode
  useEffect(() => {
    if (settingScoreTextSignMode === DrawerScoreTextSignMode.PLUS) {
      setScoreSignMode('plus')
    }
  }, [settingScoreTextSignMode])

  // questionCanvasMap
  const [questionCanvasMap, setQuestionCanvasMap] = useState<
    Record<Paper.QuestionIdentity, fabric.Canvas[]>
  >()
  // useEffect(() => {
  //   console.log('questionCanvasMap', questionCanvasMap)
  // })
  function registerQuestionCanvas(
    questionId: Paper.QuestionIdentity,
    canvas: fabric.Canvas
  ) {
    setQuestionCanvasMap(prev => {
      const newMap = { ...prev }
      if (!newMap[questionId]?.includes(canvas)) {
        newMap[questionId] = [...(newMap[questionId] ?? []), canvas]
      }
      return newMap
    })
  }
  function unregisterQuestionCanvas(
    questionId: Paper.QuestionIdentity,
    canvas: fabric.Canvas
  ) {
    setQuestionCanvasMap(prev => {
      const newMap = { ...prev }
      newMap[questionId] = newMap[questionId].filter(c => c !== canvas)
      return newMap
    })
  }

  // calculate score by question
  function computedQuestionScoreByScoreTexts(
    questionId: Paper.QuestionIdentity,
    fullScore: number,
    update: (score: number | null) => void
  ) {
    const canvases = questionCanvasMap?.[questionId]
    if (!canvases) {
      return
    }
    const scoreTexts = _.flatMap(canvases, ins => {
      return _.map(
        parseScoreTextObjects(getScoreTextObjects(ins)),
        it => it.value
      )
    })
    console.log('triggerQuestionCanvasScoreTextChanged', questionId, scoreTexts)

    if (!scoreTexts.length) {
      update(null)
      return
    }

    const sign = scoreTexts[0].sign
    const baseScore = sign === '+' ? 0 : fullScore
    const score = applyScoreText(scoreTexts, baseScore)
    update(_.clamp(score, 0, fullScore))
  }

  return {
    toolMode,
    setToolMode,
    resetToolMode: () => setToolMode('default'),

    color,
    setColor,
    fontSize,
    setFontSize,
    panSize,
    setPanSize,
    score,
    setScore,

    scoreSignMode,
    setScoreSignMode,
    scoreSignReadonly,
    setScoreSignReadonly,

    settingScoreTextSignMode,
    settingScoreAutoComputeByScoreText,

    scoreMarkerPrefix,
    setScoreMarkerPrefix,
    isScoreMarkerBracketWrapped,
    toggleScoreMarkerBracketWrapped,

    questionCanvasMap,
    registerQuestionCanvas,
    unregisterQuestionCanvas,
    computedQuestionScoreByScoreTexts
  }
})

DrawerProvider.displayName = 'ImageDrawerProvider'
export { DrawerProvider, useDrawerContent }
